@font-face {
    font-family: RubikGlitch-Regular;
    src: url("./assets/fonts/RubikGlitch-Regular.ttf");
}

@font-face {
    font-family: Raleway-Regular;
    src: url("./assets/fonts/Raleway-Regular.ttf");
}

.App {
    // width: fit-content;
    margin: auto;
    text-align: center;
    font-family: 'Raleway-Regular';
    font-weight: 600;

    .loading-container {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.478)
            /* url("https://media.giphy.com/media/8agqybiK5LW8qrG3vJ/giphy.gif") center no-repeat; */
            url("https://media.tenor.com/On7kvXhzml4AAAAi/loading-gif.gif") center no-repeat;
        /* url("https://media.tenor.com/vp3V50Hs-B8AAAAi/loading-waiting.gif") center no-repeat; */

        background-size: 10vh 10vh;
        z-index: 1;
    }

    .LoginPage {
        width: fit-content;
        padding: 0px 15px 0px 15px;
        margin: auto;
        margin-top: 5vh;
        box-sizing: border-box;
        text-align: center;

        .top-title {
            font-family: 'RubikGlitch-Regular';
            margin: 0px 0px 20px 0px;
            font-size: 70px;
            font-weight: 100;
        }

        img {
            width: 250px;
            height: 250px;
        }

        .title {
            font-size: 30px;
            font-weight: 500;
            margin: 0px;
        }

        .input-item {
            width: 80%;
            margin: auto;
            // padding: 10px 30px 10px 30px;
            box-sizing: border-box;
            text-align: left;

            p {
                font-size: 25px;
                margin: 0px;
                padding-left: 10px;
                padding-bottom: 2px;
                font-weight: 500;
            }

            .input-box {
                width: 100%;
                padding: 5px;
                box-sizing: border-box;
                border-radius: 8px;
                box-shadow: inset 0px 2px 6px rgba(0, 0, 0, 0.25);
                position: relative;

                .hide-eye {
                    position: absolute;
                    right: 15px;
                    top: 9px;
                    cursor: pointer;
                }


                input {
                    font-size: 25px;
                    width: 100%;
                    box-sizing: border-box;
                    border: none;
                    border-radius: 5px;
                    outline-width: 0;
                    background-color: rgba(0, 0, 0, 0);
                    padding-left: 10px;
                }
            }
        }

        .button {
            padding: 10px 0px 10px 0px;
            width: 80%;
            margin: auto;
            margin-top: 10px;
        }

        .forgot-password {
            font-size: 20px;
            cursor: pointer;
            margin: 5px 0px 0px 0px;

            &:hover {
                color: rgb(39, 147, 254);
            }
        }

        button {
            margin-top: 10px;
            width: 80%;
            color: black !important;
            border: 1px solid black !important;
        }
    }

    .RegisterPage {
        .main {
            width: 50vw;
            padding: 0px 15px 0px 15px;
            height: 50vh;
            margin: auto;
            margin-top: 8vh;
            box-sizing: border-box;
            text-align: center;

            .title {
                font-size: 40px;
                font-weight: 500;
                margin: 0px;
                padding-bottom: 10px;
            }

            .button {
                padding: 10px 0px 10px 0px;
                // width: 50%;
                min-width: 400px;
                margin: 30px auto 30px auto;
            }

            .input-item {
                margin: auto;
                max-width: 500px;
                padding: 5px 30px 0px 30px;
                box-sizing: border-box;
                text-align: left;

                p {
                    font-size: 25px;
                    margin: 0px;
                    padding-left: 10px;
                    padding-bottom: 5px;
                    font-weight: 500;
                }

                .input-box {
                    width: 100%;
                    padding: 5px;
                    box-sizing: border-box;
                    // border: 1px solid black;
                    background-color: #F9F9F9;
                    border-radius: 8px;
                    box-shadow: inset 0px 2px 6px rgba(0, 0, 0, 0.25);
                    position: relative;

                    .hide-eye {
                        position: absolute;
                        right: 15px;
                        top: 9px;
                        cursor: pointer;
                    }

                    input {
                        font-size: 25px;
                        width: 100%;
                        box-sizing: border-box;
                        border: none;
                        border-radius: 5px;
                        outline-width: 0;
                        background-color: rgba(0, 0, 0, 0);
                        padding-left: 10px;
                    }

                    select {
                        font-size: 25px;
                        width: 100%;
                        box-sizing: border-box;
                        border: none;
                        border-radius: 5px;
                        outline-width: 0;
                        background-color: rgba(0, 0, 0, 0);
                    }
                }

            }

            .forgot-password {
                font-size: 20px;
                cursor: pointer;

                &:hover {
                    color: rgb(39, 147, 254);
                }
            }
        }
    }

    .MainPage {
        font-size: 20px;
        position: relative;
        // padding-bottom: 100px;

        &.curser {
            cursor: pointer;
        }

        .row {
            display: flex;
            align-items: center;
        }

        .header {
            width: 100%;
            padding: 5px 30px 5px 30px;
            box-sizing: border-box;
            box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
            background-color: rgb(255, 255, 255);
            position: fixed;
            top: 0px;
            left: 0px;
            z-index: 1;
            display: flex;
            align-items: center;

            .user-name {
                position: absolute;
                left: 50px;
            }

            p {
                font-family: 'RubikGlitch-Regular';
                margin: 0px;
                width: fit-content;
                margin: auto;
                font-size: 50px;
                font-weight: 100;
            }

            .button {
                background-color: rgb(255, 255, 255);
                color: black;
                position: absolute;
                right: 0px;
                padding: 0px;

                &:hover {
                    color: rgb(39, 147, 254);
                }
            }

            button {
                margin: 0px;
                height: 100%;
                color: black !important;
                box-shadow: none !important;
            }
        }

        .squares {
            width: fit-content;
            margin: 80px auto 10px auto;

            .unit-squares {
                width: fit-content;
                // border: 1px solid black;

                .square {
                    width: 20px;
                    height: 20px;
                    background-color: rgb(255, 255, 255);
                    border: 1px solid black;

                    &.black {
                        background-color: rgb(0, 0, 0);
                    }
                }
            }
        }

        .total-frame {
            padding: 10px 0px 10px 0px;
            width: 100vw;
            max-width: 530px;
            white-space: pre-wrap;
            word-wrap: break-word;
            display: inline-block;
            word-break: break-all;
        }

        .row-center {
            min-height: 52px;
            width: fit-content;
            margin: auto;
            text-align: left;
            display: flex;
            align-items: center;

            p {
                margin: 0px 30px 0px 5px;
            }

            input {
                width: 20px;
                height: 30px;
            }

            .current-frame {
                width: 100vw;
                max-width: 530px;
                white-space: pre-wrap;
                word-wrap: break-word;
                display: inline-block;
                word-break: break-all;
            }
        }

        .sliders {
            display: flex;
            padding: 10px 0px 10px 0px;
            width: fit-content;
            margin: auto;
            align-items: center;

            p {
                margin: auto;
            }

            .slider-box {
                // width: 400px;
                padding: 0px 30px 0px 30px;

                .row {
                    display: flex;
                    align-items: center;

                    input {
                        margin: 0px 10px 0px 10px;
                        font-size: 20px;
                        width: 100px;
                        padding: 5px 10px 5px 10px;
                    }

                    textarea {
                        border: 1px solid black;
                        margin: 0px 10px 0px 10px;
                        min-height: 84px;
                        font-size: 20px;
                        font-family: 'Raleway-Regular';
                        width: 100vw;
                        max-width: 310px;
                        padding: 5px 10px 5px 10px;
                        resize: none;
                        box-sizing: border-box;
                        -ms-overflow-style: none;

                        &::-webkit-scrollbar {
                            display: none;
                        }
                    }

                    .nano-buttons {
                        display: grid;

                        svg {
                            cursor: pointer;
                            width: 30px;
                            height: 30px;

                            &:hover {
                                color: rgb(39, 147, 254);
                            }

                            // &:first-child{
                            //     margin-bottom: 10px;
                            // }
                        }
                    }
                }

            }
        }

        .go-to-frame {
            display: flex;
            padding: 10px 0px 0px 0px;
            width: fit-content;
            margin: auto;
            align-items: center;

            p {
                margin: 0px;
            }

            textarea {
                border: 1px solid black;
                margin: 0px 10px 0px 10px;
                min-height: 60px;
                font-size: 20px;
                width: 100vw;
                max-width: 460px;
                padding: 5px 10px 5px 10px;
                resize: none;
                box-sizing: border-box;
                -ms-overflow-style: none;

                &::-webkit-scrollbar {
                    display: none;
                }
            }
        }

        .slider {
            margin: auto;
            max-width: 1600px;
            height: 50px;
            // padding-top: 30px;
            display: flex;
            align-items: center;

            .button {
                align-items: center;
                user-select: none;
                padding-bottom: 9px;
                margin: 0px 5px 0px 5px;

                &.left {
                    margin-right: 40px;
                }

                &.right {
                    margin-left: 40px;
                }
            }
        }

        .reset-all {
            width: 100%;
            margin-top: 10px;
            // margin-bottom: 100px;

            .button {
                margin: auto;
                width: 500px;
                user-select: none;
            }
        }

        .saving-comment {
            padding: 10px 0px 30px 0px;
            margin: auto;
            width: fit-content;

            p {
                margin: 0px;
            }

            textarea {
                padding: 5px;
                min-width: 800px;
                max-width: 800px;
                min-height: 50px;
                font-size: 20px;
            }

            .buttons {
                margin: auto;

                .button {
                    width: 300px;
                    margin-left: 10px;
                    margin-right: 10px;
                }
            }
        }

        .horizontal-line {
            width: 95%;
            margin: auto;
            box-sizing: border-box;
            height: 2px;
            background-color: black;
        }

        .saved-items {
            padding-top: 20px;

            .saved-item {
                align-items: center;
                margin: auto;
                width: fit-content;
                padding: 3px;

                .squares {
                    margin: 0px;
                    border: 1px solid black;

                    .square {
                        width: 6px !important;
                        height: 6px !important;
                        border: none;
                    }
                }

                .comment-area {
                    margin-left: 20px;
                    border: 1px solid black;
                    min-height: 90px;
                    min-width: 800px;
                    max-width: 800px;
                    font-size: 20px;
                    white-space: pre-wrap;
                    word-wrap: break-word;
                    display: inline-block;
                    text-align: left;
                    padding: 5px;
                    position: relative;

                    p {
                        margin: 0px;
                        font-size: 13.6px;
                        color: rgba(0, 0, 0, 0.439);
                        position: absolute;
                        right: 5px;
                        bottom: 5px;
                        white-space: pre-wrap;
                        word-wrap: break-word;
                        display: inline-block;
                        width: 100%;
                        padding-left: 10px;
                        box-sizing: border-box;
                        text-align: right;
                        word-break: break-all;
                    }
                }

                &>.row:nth-child(2) {
                    justify-content: end;
                    padding: 5px 0px 5px 100px;
                    justify-content: space-between;
                    align-items: center;

                    p {
                        margin: 0px;
                    }

                    .svg-icons {
                        display: flex;
                        align-items: center;

                        .svg-icon-container {
                            position: relative;
                            align-items: center;

                            .svg-icon {
                                color: rgb(27, 89, 195);
                                // margin: 0px 5px 0px 5px;
                                height: 100%;
                                border-radius: 100px;
                                // background-color: aquamarine;
                                padding: 7px;
                                cursor: pointer;

                                &:hover {
                                    color: white;
                                    background-color: rgb(27, 89, 195);
                                }
                            }

                            .hover-up {
                                position: absolute;
                                bottom: 46px;
                                min-width: 60px;
                                background-color: rgb(211, 211, 211);
                                padding: 2px 20px 5px 20px;
                                left: -34px;
                                border-radius: 5px;
                            }
                        }
                    }
                }
            }
        }
    }

    .buttons {
        width: fit-content;
        display: flex;
        margin: auto;

        .button {
            width: 300px;
        }
    }

    .button {
        user-select: none;
        background-color: rgb(68, 114, 196);
        width: 150px;
        text-align: center;
        padding: 10px 0px 10px 0px;
        border-radius: 10px;
        color: white;
        margin: 0px 10px 0px 10px;
        cursor: pointer;

        &:hover {
            background-color: rgb(27, 89, 195);
        }

        &.disabled {
            background-color: rgb(100, 119, 153);
            cursor: default;
        }

        &.draw-mode {
            position: absolute;
            top: 125px;
            left: calc(50vw - 417px);
        }

        &.donate {
            position: absolute;
            top: 110px;
            height: 50px;
            line-height: 50px;
            right: calc(50vw - 417px);
            background-color: #ffc43a;
            color: #121661;
            align-items: center;

            &:hover {
                background-color: #ffc43ac0;
            }
        }

        &.download {
            position: absolute;
            top: 200px;
            color: white;
            right: calc(50vw - 417px);
            background-color: rgb(68, 114, 196);
            align-items: center;

            &:hover {
                background-color: rgb(27, 89, 195);
            }
        }
    }

    .button-gotoUp {
        position: fixed;
        right: 30px;
        bottom: 30px;
        margin: 0px;
        padding: 10px;
        font-size: 30px;
        width: 40px;
        height: 40px;
        background-color: rgb(68, 114, 196);
        ;
        // border: 1px solid rgba(0, 0, 0, 0.738);
        color: white;
        border-radius: 100px;
        cursor: pointer;
        opacity: 0.5;

        &:hover {
            opacity: 1;
        }
    }

    .draw-mode-toggle {
        position: absolute;
        top: 105px;
        left: calc(50vw - 417px);
        p{
            margin: 0px;
        }
        .flex{
            margin: auto;
            text-align: center;
            display: inline-flex;
            align-items: center;
            padding-bottom: 30px;
            svg{
                width: 40px;
                height: 40px;
                cursor: pointer;
            }
            p{
                margin: 0px 5px 0px 5px;
                font-weight: 800;
            }
        }
    }

    .blue {
        color: rgb(68, 114, 196);
    }

    .hidden {
        display: none;
    }
}